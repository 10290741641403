import { Link, Text, HStack, Tooltip, Box, useBreakpointValue, chakra, shouldForwardProp } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';

import type { NavItem } from 'types/client/navigation-items';

import { route } from 'nextjs-routes';

import useIsMobile from 'lib/hooks/useIsMobile';
import { isInternalItem } from 'lib/hooks/useNavItems';
import IconSvg from 'ui/shared/IconSvg';

import NavLinkIcon from './NavLinkIcon';
import useColors from './useColors';
import useNavLinkStyleProps from './useNavLinkStyleProps';

type Props = {
  item: NavItem;
  isCollapsed?: boolean;
  px?: string | number;
  className?: string;
  onClick?: () => void;
}

const NavLinkItem = ({ item, isCollapsed, px, className, onClick }: Props) => {
  const isMobile = useIsMobile();
  const colors = useColors();

  const isInternalLink = isInternalItem(item);

  // const styleProps = useNavLinkStyleProps({ isActive: isInternalLink && item.isActive });

  const href = isInternalLink && !!item.nextRoute ? route(item.nextRoute) : item?.url;
  const isActive = isInternalLink && item.isActive

  const itemProps = {
    py: 2.5,
    display: 'flex',
    color: isActive ? "bluecent" : "gray.800",
    _hover: { bgColor: "bghover" },
    borderRadius: 'base',
  }

  const textProps = {
    variant: 'inherit',
    fontSize: 'sm',
    lineHeight: '20px',
    // opacity: { base: '1', lg: isExpanded ? '1' : '0', xl: isCollapsed ? '0' : '1' },
    transitionProperty: 'opacity',
    transitionDuration: 'normal',
    transitionTimingFunction: 'ease',
  }

  // const href = isInternalLink ? route(item.nextRoute) : item.url;

  const content = (
    <Link
      // href={ item.text === "ETOBE Faucet" ? 'https://faucet.tobescan.com/faucet' : href }
      href={ href }
      target={ isInternalLink ? '_self' : item?.url === '#' ? '_self' : '_blank' }
      { ...itemProps }
      w={{ base: '100%' }}
      display="block"
      px={{ base: 2, lg: 2 , xl: 3 }}
      py={{ base: 2}}
      aria-label={ `${ item.text } link` }
      whiteSpace="nowrap"
      onClick={ onClick }
      _hover={{
        '&': {
          bgColor: 'bghover',
        },
      }}
    >
      <Tooltip
        label={ item.text }
        hasArrow={ false }
        isDisabled={ true }
        placement="bottom-end"
        variant="nav"
        gutter={ 20 }
        color={ isInternalLink && item.isActive ? colors.text.active : colors.text.hover }
      >
        <HStack spacing={ 3 } overflow="hidden">
          <Text { ...textProps }>
            <span>{ item.text }</span>
            { !isInternalLink && <IconSvg name="arrows/north-east" boxSize={ 4 } color="text_secondary" verticalAlign="middle"/> }
          </Text>
        </HStack>
      </Tooltip>
    </Link>
  );

  return (
    <Box as="li" listStyleType="none" w="100%" className={ className }>
      { isInternalLink && !!item.nextRoute ? (
        <NextLink href={ item.nextRoute } legacyBehavior>
          { content }
        </NextLink>
      ) : content }
    </Box>
  );
};

const NavLinkChakra = chakra(NavLinkItem, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);

    if (isChakraProp && prop !== 'px') {
      return false;
    }

    return true;
  },
});

export default React.memo(NavLinkChakra);
